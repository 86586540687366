import { graphql, useStaticQuery } from "gatsby"
import { uniqBy, sortBy } from "lodash"

export default (contactLocationUids, specialisationUids) => {
  const data = useStaticQuery(graphql`
    query SimilarHelpersQuery {    
      specialisations: allPrismicSpecialisation {
        edges {
          node {
            uid
          }
        }
      }
      contactLocations: allPrismicContactLocation {
        edges {
          node {
            id
            uid
            lang
            data {
              title {
                text
              }
              long_title {
                text
              }
              gallery {
                image {
                  alt
                  gatsbyImageData(layout: FULL_WIDTH)
                  url
                }
              }
              description {
                html
              }
              city {
                text
              }
              address {
                text
              }
              coordinates {
                latitude
                longitude
              }
              helpers {
                helper {
                  document {
                    ... on PrismicHelper {
                      id
                      uid
                      lang
                      data {
                        name {
                          text
                        }
                        ratings {
                          field
                          value
                        }
                        profile_image {
                          gatsbyImageData(layout: CONSTRAINED, width: 500)
                        }
                        specialisations {
                          specialisation: specialization {
                            document {
                              ... on PrismicSpecialisation {
                                id
                                uid
                                data {
                                  title {
                                    text
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const contactLocations = data.contactLocations.edges.map(r => r.node)
  const specialisations = data.specialisations.edges.map(r => r.node)

  const contactLocationsToHelpersMap = []

  contactLocations.map(cl => {
    cl.data.helpers
      .filter(h => h.helper.document)
      .map((h, key) => {
        h.helper.document.position = key
        if (!contactLocationsToHelpersMap[cl.uid]) {
          contactLocationsToHelpersMap[cl.uid] = []
        }

        h.helper.document.data.specialisations.forEach(({ specialisation }) => {
          if (!contactLocationsToHelpersMap[cl.uid][specialisation.document.uid]) {
            contactLocationsToHelpersMap[cl.uid][specialisation.document.uid] = []
          }
          contactLocationsToHelpersMap[cl.uid][specialisation.document.uid].push(h.helper.document)
        })
      })
  })

  let similar = []

  if (!contactLocationUids) {
    contactLocationUids = contactLocations.map(cl => cl.uid)
  }

  if (!specialisationUids) {
    specialisationUids = specialisations.map(s => s.uid)
  }

  contactLocationUids.forEach(contactLocationUid => {
    specialisationUids.forEach(specialisationUid => {
      if (contactLocationsToHelpersMap[contactLocationUid] && contactLocationsToHelpersMap[contactLocationUid][specialisationUid]) {
        similar = [...similar, ...contactLocationsToHelpersMap[contactLocationUid][specialisationUid]]
      }
    })
  })

  return sortBy(uniqBy(similar, i => i.uid), ['position'])
}
