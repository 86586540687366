import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import makeStyles from '@material-ui/core/styles/makeStyles'
import { GatsbyImage } from "gatsby-plugin-image"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import Rating from "../Rating"
import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  media: {
    height: 250,
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  name: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
  },
  specialisation: {
    color: theme.palette.primary.main,
    fontSize: 20,
    marginBottom: 10,
  },

  actions: {
    flexDirection: "column",
    padding: 0,
  },

  rating: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },

  button: {
    marginBottom: theme.spacing(1),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const HelperDetailCard = ({ helper, onSelect }) => {
  const classes = useStyles()

  return (
    <Card elevation={0} square className={classes.locationBox}>
      <CardMedia>
        <GatsbyImage
          className={classes.media}
          image={helper.data.profile_image.gatsbyImageData}
          alt={helper.data.profile_image.alt ?? ""}
        />
      </CardMedia>
      <CardContent className={classes.content}>
        <Typography variant="h5" className={classes.name}>
          {helper.data.name.text}
        </Typography>

        {helper.data.specialisations.length > 0 && helper.data.specialisations.map(({ specialisation }) =>
          <Typography variant="h5" className={classes.specialisation} key={specialisation.document.id}>
            {specialisation.document.data.title.text}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {helper.data.ratings.map(rating => (
          <Rating
            className={classes.rating}
            key={shortid.generate()}
            field={rating.field}
            value={rating.value || 5}
          />
        ))}
        <CustomButton
          size="small"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => onSelect(helper.uid)}
        >
          Alege acest MONZA ARES HELPER
        </CustomButton>
      </CardActions>
    </Card>
  )
}

export default HelperDetailCard

HelperDetailCard.defaultProps = {
  onSelect: () => { },
}

HelperDetailCard.propTypes = {
  helper: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
}
