import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"

import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"

import clsx from "clsx"

import StarIcon from "@material-ui/icons/Star"

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: "#aaa",
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      fontWeight: 700,
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },

    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}))

const Rating = ({ field, value, ...props }) => {
  const classes = useStyles()

  const { className } = props

  const getRatingStars = v => {
    const ratings = []
    for (let i = 0; i < v; i += 1) {
      ratings.push(
        <StarIcon style={{ fontSize: 20 }} key={shortid.generate()} />
      )
    }

    return ratings
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      {...props}
      className={clsx(className, classes.wrapper)}
    >
      <Grid item>{field}</Grid>
      <Grid item>{getRatingStars(value)}</Grid>
    </Grid>
  )
}

export default Rating

Rating.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}
