import React from "react"
import PropTypes from "prop-types"

import BaseCarousel from "./BaseCarousel"
import HelperDetailCard from "../cards/HelperDetailCard"

const HelpersCarousel = ({ helpers, onSelect }) => (
  <BaseCarousel
    elements={helpers}
    render={helper => (
      <HelperDetailCard helper={helper} onSelect={onSelect} />
    )}
  />
)

export default HelpersCarousel

HelpersCarousel.defaultProps = {
  onSelect: () => { },
}

HelpersCarousel.propTypes = {
  helpers: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
}
